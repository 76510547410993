<template>
    <div class="flex completion-main">
        <br>
        <h4>Photo (optional)</h4>

        <Photo/>

        <h4>About yourself (optional)</h4>

        <Textarea v-model="presentation" :autoResize="true" rows="5" cols="30" placeholder="Write something about yourself." />
            <br>
        <Textarea v-model="expectations" :autoResize="true" rows="5" cols="30" placeholder="Write about your expectations in terms of coaching." />

            <br>
        <div class="justify-center">
        <Button label="Finish and review" icon="pi pi-check" class="w-8" v-on:click="submit"></Button>
        </div>

    </div>
</template>

<script>
import { WebCam } from "vue-web-cam";
import UserService from '../../service/UserService';
import Photo from '../C_Photo.vue';

export default {
    components: {Photo},
    data() {
        return {
            presentation: "",
            expectations: "",
        }
    },
    userService: null,
    created() {
        this.userService = new UserService();
    },
    methods: {
        submit(event) {
            return this.userService.updateUser(
                {
                    "about": this.presentation,
                    "expectations": this.expectations
                }
            ).then(() => this.$router.push("/signup/review"));
        },
    }
}
</script>
<style scoped lang="scss">
.completion-main {
    flex-direction: column;
}
</style>
